import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=47cee304&scoped=true&"
import script from "./Container.vue?vue&type=script&lang=ts&"
export * from "./Container.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47cee304",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsComponentsBasicBanner: require('/var/www/packages/theme/components/cms-components/basic-banner/BasicBanner.vue').default})
