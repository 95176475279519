














import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import type { BasicBanner } from '~/components/cms-components/basic-banner/BasicBanner.vue';
import useWindowResize from '~/hooks/useResizeWindow';

type BasicBannerContainer = {
  id: number;
  code: 'basicbanner_container';
  elements: BasicBanner[];
};

export default defineComponent({
  name: 'Container',
  props: {
    data: {
      type: Object as PropType<BasicBannerContainer>,
      default: () => ({})
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();

    const mobileClass = ref(
      props.data.elements.length > 1 ? 'sm-cols-5' : 'sm-cols-6'
    );

    return { isDesktop, mobileClass };
  }
});
