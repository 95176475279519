import { render, staticRenderFns } from "./BasicBanner.vue?vue&type=template&id=9a74b0a0&scoped=true&"
import script from "./BasicBanner.vue?vue&type=script&lang=ts&"
export * from "./BasicBanner.vue?vue&type=script&lang=ts&"
import style0 from "./BasicBanner.vue?vue&type=style&index=0&id=9a74b0a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a74b0a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsImageOrFallback: require('/var/www/packages/theme/components/atoms/ImageOrFallback.vue').default,AtomsKEButton: require('/var/www/packages/theme/components/atoms/KEButton.vue').default,MoleculesCmsCustomButtonWrapper: require('/var/www/packages/theme/components/molecules/CmsCustomButtonWrapper.vue').default,MoleculesCmsUrlWrapper: require('/var/www/packages/theme/components/molecules/CmsUrlWrapper.vue').default})
