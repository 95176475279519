



































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref
} from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';
import { getCustomFieldByKey } from '~/helpers/utils';

export type BasicBanner = {
  id: number;
  code: string;
  number_of_columns: number;
  urls: [
    {
      url: string;
      media_path: string;
    }
  ];
  custom_fields: [
    {
      key: 'title';
      value_string: string;
    },
    {
      key: 'subtitle';
      value_string: string;
    },
    {
      key: 'text_color';
      value_select: 'white' | 'black';
    },
    {
      key: 'button_text';
      value_string: string;
    }
  ];
};

export default defineComponent({
  name: 'BasicBanner',
  props: {
    data: {
      type: Object as PropType<BasicBanner>,
      default: () => ({
        id: 0,
        urls: [
          {
            media_path: '',
            url: ''
          }
        ],
        custom_fields: () => []
      })
    }
  },
  setup(props) {
    const { isDesktop } = useWindowResize();
    const banner = ref();

    const isSmallCard = computed(
      () => !isDesktop.value && props.data.number_of_columns === 6
    );
    const imageHeight = computed(() => {
      if (isDesktop.value) {
        return props.data.number_of_columns === 6 ? 483 : 574;
      } else {
        return props.data.number_of_columns === 6 ? 238 : 320;
      }
    });

    const subtitleClass = computed(() =>
      props.data?.number_of_columns === 12 ? 'full-width-subtitle' : ''
    );

    const media_path = props.data.urls?.[0]?.media_path;

    const urlData = computed(() => props.data.urls?.[0]);

    const textColor = () => {
      return getCustomFieldByKey(props.data.custom_fields, 'text_color')
        ?.value_select;
    };

    const getField = (field: string) => {
      return getCustomFieldByKey(props.data.custom_fields, field)?.value_string;
    };

    onMounted(() => {
      if (isSmallCard.value) {
        banner.value.classList.remove('sm-cols-6');
        banner.value.classList.add('sm-cols-5');
      }
    });

    return {
      isDesktop,
      banner,
      urlData,
      imageHeight,
      subtitleClass,
      isSmallCard,
      media_path,
      textColor,
      getField
    };
  }
});
